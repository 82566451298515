import { ACTION_TYPES } from "../actions/types";
import { MESSAGE_STATUS, REPORT_RANGES } from "../constants";

const initialState = {
  setupStatus: -1, // whether the app is setup or not. -1=not done yet, 0=failed, 1=success
  reportRange: REPORT_RANGES.MONTH,
  messages: [],
  organizations: [],
  organizationId: "",
  messageStatus: MESSAGE_STATUS.UNDELIVERED,
  dateRange: [],
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case "SET_DATE_RANGE":
      return {
        ...state,
        dateRange: action.payload,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "UPDATE_REPORT_RANGE":
      return {
        ...state,
        reportRange: action.payload,
      };
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_DURATION:
      return {
        ...state,
        reportDuration: action.payload,
      };

    case ACTION_TYPES.GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case "GET_ORGANIZATIONS":
      return {
        ...state,
        organizations: action.payload,
      };


      case ACTION_TYPES.UPDATE_MESSAGE_STATUS:
            return {
        ...state,
        messageStatus: action.payload,
      };


    default:
      return state;
  }
}
