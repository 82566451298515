import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { updateDrawer } from "../../../store/actions/miscAction";

const MessagesTable = ({ messages }) => {
    const dispatch = useDispatch();
      const openDrawer = (id) => {
    const drawer = {
      title: "Message Details",
      isOpen: 1,
      content: { component: "MessageCard", id },
    };
    dispatch(updateDrawer(drawer));
  };

  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Message Created</Th>
            <Th>Phone Number</Th>
            <Th>Message</Th>
            <Th>Delivery Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {messages.map((message, i) => {
            console.log(message);
            const { mobile, deliveryStatus } = message;
            return (
              <>
                <Tr key={message.id} onClick={()=>openDrawer(message.id)} cursor={'pointer'}>
                  <Td>{i + 1}</Td>
                  <Td>
                    {" "}
                    {dayjs.unix(message.created).format("YYYY-MM-DD HH:mm:ss")}
                  </Td>
                  <Td>{mobile}</Td>
                  <Td>{message.message}</Td>
                  <Td>
                    {Number(deliveryStatus) === 1 ? "Delivered" : "Undelivered"}
                  </Td>
                </Tr>
              </>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MessagesTable;
