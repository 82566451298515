import axios from "axios";
import io from "socket.io-client";
import "react-toastify/dist/ReactToastify.css";
import { ACTION_TYPES } from "./types";
import store from "../index";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import _ from "lodash";

axios.defaults.baseURL = "https://cx.api.averly.com.na";

let socket = {}; // Declare a variable to hold the Socket.IO client instance
export const listenToEvent =
  (eventName, updateType, organizationCheck = 0) =>
  (dispatch) => {
    socket[eventName] = io("https://cx.api.averly.com.na");
    const startType = updateType + "_START";
    const payload = 1;
    dispatch({ type: startType, payload: 1 });
    socket[eventName].on(`${eventName}-updated`, (res) => {
      let type, payload;
      if (res.new_val != null) {
        type = updateType + (res.old_val != null ? "_UPDATE" : "_CREATE");
        payload = res.new_val;
      } else {
        type = updateType + "_DELETE";
        payload = res.old_val;
      }

      // dispatch only if the payload is for the active organization
      let organizationId;
      if (organizationCheck) {
        const { userData: authenticatedUser } = store.getState().authReducer;
        organizationId =
          authenticatedUser.activeOrganization &&
          authenticatedUser.activeOrganization.length
            ? authenticatedUser.activeOrganization
            : authenticatedUser.organizationId;
      }

      if (!organizationCheck || payload.organizationId === organizationId) {
        dispatch({
          type,
          payload,
        });
      }
    });
  };

export const fetchData = async (
  endpoint,
  params,
  singleResponse,
  dispatch,
  actionType
) => {
  try {
    const res = await axios.get(endpoint, { params });
    const payload = singleResponse
      ? res.data.length
        ? res.data[0]
        : {}
      : res.data;
    dispatch({ type: actionType, payload });
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    throw err;
  }
};

// GET_ORGANIZATIONS
export const getOrganizations = (organizationId) => async (dispatch) => {
  const endpoint = "/admin/organizations/";
  const payload = {
    organizationId,
  };
  const actionType = "GET_ORGANIZATIONS";
  const singleResponse = 0;
  await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
};

export const updateOrganizationId = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_ORGANIZATION_ID",
    payload,
  });

  // do reset
  dispatch(setDateRange(""));
};

export const updateReportRange = (payload) => (dispatch) => {
  dispatch({
    type: "UPDATE_REPORT_RANGE",
    payload,
  });
  // do reset
  dispatch(setDateRange(""));
};

export const setDateRange = (dateRange) => async (dispatch) => {
  dispatch({
    type: "SET_DATE_RANGE",
    payload: dateRange,
  });
};

// get reportsData
export const getMessages =
  (dateRange = null) =>
  async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    const baseUrl =
      process.env.REACT_APP_SMS_API || "https://sms.api.averly.com.na";
    const endpoint = `${baseUrl}/sms`;
    const createdFrom = !_.isEmpty(dateRange)
      ? dateRange[0]
      : dayjs().subtract(1, "day").startOf("day").unix();
    const createdTo = !_.isEmpty(dateRange)
      ? dateRange[1]
      : dayjs().subtract(1, "day").endOf("day").unix();
    const payload = {
      createdFrom,
      createdTo,
    };
    const actionType = ACTION_TYPES.GET_MESSAGES;
    const singleResponse = 0;
    toast.info("Fetching data");
    await fetchData(endpoint, payload, singleResponse, dispatch, actionType);
    dispatch({ type: "SET_LOADING", payload: false });
  };


  export const updateMessageStatus = (payload) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_MESSAGE_STATUS,
      payload,
    });
  };