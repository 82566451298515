import { connect } from "react-redux";
import { Box, Image } from "@chakra-ui/react";

import {
  updateReportRange,
} from "../store/actions/defaultAction";

import Nav from "./sidebar/Nav";
import ReportRangeSelection from "./filterForm/ReportRangeSelection";

import Logo from '../assets/img/logo-dark.svg'

function Sidebar() {
  
  return (
    <Box
      className="sidebar"
      w="260px"
      backgroundColor="var(--white)"
      borderRight="1px solid #ebebeb"
      position="fixed"
      height="100vh"
      overflowY="auto"
      paddingBottom={0}
    >
      <Box p="15px 15px 0">
        <Image src={Logo} alt="logo" />
      </Box>
      <Box p="15px 15px 0">
        <ReportRangeSelection />
        <Box mt="15px">
          <Nav />
        </Box>

      </Box>
    </Box>
  );
}

export default connect(null, {
  updateReportRange,
})(Sidebar);
