import {
    Avatar,
    Box,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { IoChevronDownOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

import { updateAppStatus } from "../../store/actions/authActions";

import { APP_STATUSES } from "../../store/constants";

const UserAvatar = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((store) => store.authReducer);
    const logout = () => {
        Cookies.remove("accessToken");
        Cookies.remove("userId");
        dispatch(updateAppStatus(APP_STATUSES.LOGGED_OUT));
        window.location.replace(
        `https://auth.averly.com.na?origin=${window.location.href}`
        );
    };

    return (
        <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack spacing="10px">
                <Avatar
                  size="sm"
                  name={userData?.name +" "+ userData?.lastname}
                  bg="var(--black)"
                />
                <Text fontSize="14px" fontWeight={600} h="18px">
                    {userData?.name} {userData?.lastname}
                  </Text>
                <Box display={{ base: "none", md: "flex" }}>
                  <IoChevronDownOutline />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("var(--borderGrey)", "gray.700")}
            >   
                <Box px={4} py={3}>
                    <Text fontSize="14px">{userData?.email}</Text>
                </Box>
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    )
}

export default UserAvatar;