// Auth
export const FETCH_CLOUD_ORGANIZATIONS = "FETCH_CLOUD_ORGANIZATIONS";

//Add all action types here
export const ACTION_TYPES = Object.freeze({
  //Default reducer
  UPDATE_REPORT_DURATION: "UPDATE_REPORT_DURATION",
  UPDATE_APP_STATUS: "UPDATE_APP_STATUS",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  UPDATE_REPORT_RANGE: "UPDATE_REPORT_RANGE",
  GET_MESSAGES: "GET_MESSAGES",
  UPDATE_MESSAGE_STATUS: "UPDATE_MESSAGE_STATUS",

  //Auth Reducer
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_ID: "SET_USER_ID",
  UPDATE_VERIFY_STATUS: "UPDATE_VERIFY_STATUS",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",

  //permissions reducer
  GET_IAM_ROLES: "GET_IAM_ROLES",
  GET_IAM_PERMISSIONS: "GET_IAM_PERMISSIONS",
  SET_PERMISSIONS_LOADING: "SET_PERMISSIONS_LOADING",

});
