import { Box, Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import FilterForm from "../../../components/FilterForm";
import MonthlyMessagesAnalysisReport from "../../../components/sms/reports/monthly/MonthlyMessagesAnalysis";
import Loader from "../../../components/loader";
import _ from "lodash";
import { Helmet } from "react-helmet";

const MonthlyMessagesAnalysis = ({
  showFilterForm = true,

  showTitle = 1,
}) => {
  const { loading, messages } = useSelector(
    (state) => state.defaultReducer
  );

  const title = `Messages Analysis`;

  return (
    <Box>
      {showTitle === 1 && (
        <Helmet>
          <title>Monthly Messages Analysis</title>
        </Helmet>
      )}
      {showFilterForm ? <FilterForm /> : <></>}
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(messages) ? (
            <>
              <Heading fontSize="34px" color="#222" fontWeight="700">
                {title}
              </Heading>
              <MonthlyMessagesAnalysisReport />
            </>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default MonthlyMessagesAnalysis;
