import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";
import MessagesTable from "../../tables/MessagesTable";
import DrawerComponent from "../../misc/Drawer";

const DailyMessageDetails = () => {
  const { messages, messageStatus } = useSelector(
    (state) => state.defaultReducer
  );
  const messagesClone = _.cloneDeep(messages).filter(
    (a) =>
      !messageStatus.length ||
      (messageStatus.length &&
        Number(a.deliveryStatus) === Number(messageStatus))
  ).sort((a, b) => b.created - a.created);

  return (
    <Box mt={4}>
      <DrawerComponent />
      <MessagesTable messages={messagesClone}/>
    </Box>
  );
};

export default DailyMessageDetails;
