import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, Button } from "@chakra-ui/react";

import DatePicker from "./filterForm/DatePicker";
import { getMessages } from "../store/actions/defaultAction";
import MessageStatusSelection from "./filterForm/MessageStatusSelection";

function FilterForm({ isMessageDetailsReport = false }) {
  const dispatch = useDispatch();
  const { dateRange } = useSelector(
    (store) => store.defaultReducer
  );

  return (
    <Box
      overflow="visible"
      borderBottom="1px solid #ebebeb"
      m="0 -15px 30px"
      p="0 15px 15px"
    >
      <HStack overflow="visible" spacing="15px">
        <Box>
          <DatePicker />
        </Box>
        {isMessageDetailsReport ? (
          <Box>
            <MessageStatusSelection />
          </Box>
        ) : null}

        <Box>
          <Button
            w="200px"
            h="56px"
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              dispatch(getMessages(dateRange));
            }}
          >
            Search
          </Button>
        </Box>
      </HStack>
    </Box>
  );
}

export default FilterForm;
