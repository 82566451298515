export const version = "1.8.22";

export const REPORT_RANGES = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  RT: "realtime",
});

export const APP_STATUSES = Object.freeze({
  LOGGED_IN: 1,
  PEDING_LOGIN: 2,
  LOGGED_OUT: 0,
});

export const MESSAGE_STATUS = Object.freeze({
  DELIVERED: 1,
  UNDELIVERED:  0
})

export const biLinks = Object.freeze([
  {
    name: "Queue Management",
    link: "https://qm.reports.averly.com.na/",
    icon: "IoWalkOutline",
    status: 1,
  },
  {
    name: "Customer Feedback",
    link: "https://cf.reports.averly.com.na/",
    icon: "IoChatboxEllipsesOutline",
    status: 1,
  },
  {
    name: "Self Service",
    link: "https://ss.reports.averly.com.na/",
    icon: "IoQrCodeOutline",
    status: 1,
  },
  {
    name: "Digital Signage",
    link: "https://ds.reports.averly.com.na/",
    icon: "IoImagesOutline",
    status: 1,
  }
]);

export const navLinks = Object.freeze([
  {
    name: "month",
    links: [
      {
        name: "Analytics",
        links: [
          {
            name: "Messages Analysis",
            link: "/monthly/messages-analysis/",
          },
          {
            name: "Message Details",
            link: "/monthly/message-details/",
          },
        ],
      },
    ],
  },

  {
    name: "day",
    links: [
      {
        name: "Analytics",
        links: [
          {
            name: "Messages Analysis",
            link: "/daily/messages-analysis/",
          },
          {
            name: "Message Details",
            link: "/daily/message-details/",
          },
        ],
      },
    ],
  },
]);


export const PRODUCT_ID = "5932d396-1559-4afa-8f72-bd57c53ac2ee"; //SMS product ID
export const PRODUCT_SERVICE_ID = "67559e47-4e44-4f5c-b43e-9b7a54e7b236"; //SMS Reports product service id


export const stepsDefault = Object.freeze([
  {
    type: "reportRange",
    title: "Select Report Range",
    description: "On the Left Sidebar select a report range between, Monthly, Weekly, Daily and realtime.",
    status: 0
  },
  {
    type: "reportType",
    title: "Select Report Type",
    description: "Select a report type from the left sidebar navigation.",
    status: 0
  },
  {
    type: "dateRange",
    title: "Select a date range",
    description: "Select a date range to view reports for that period.",
    status: 0
  }
]);
