import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import {
  getMessages,
  getOrganizations,
} from "./store/actions/defaultAction";

import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";

//reports
//daily
import DailyMessagesAnalysis from "./pages/sms/daily/DailyMessagesAnalysis.js";
import DailyMessageDetails from "./pages/sms/daily/DailyMessageDetails.js";

//Monthly
import MonthlyMessagesAnalysis from "./pages/sms/monthly/MonthlyMessagesAnalysis.js";

//No Permissions
import NoPermissions from "./pages/NoPermissions";

// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";
import "./assets/css/Inputs.css";

import Loader from "./components/loader";
import { useDispatch } from "react-redux";
import { updateAppStatus, verifyToken } from "./store/actions/authActions";
import { getIamRoles } from "./store/actions/permissionsActions";
import usePermissions from "./hooks/usePermissions";
import _ from "lodash";

function App() {
  const { read } = usePermissions();
  const dispatch = useDispatch();
  const { appStatus, userId, verifyStatus, userData } = useSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    dispatch(verifyToken());
  }, []);
  useEffect(() => {
    if (userId && verifyStatus === 1) {
      dispatch(getIamRoles());
    }
  }, [userId, verifyStatus]);
  useEffect(() => {
    if (appStatus) {
      dispatch(getOrganizations());
    }
  }, [appStatus]);

  useEffect(() => {
    if (Number(read) === 1 && verifyStatus === 1) {
      dispatch(updateAppStatus(1));
    } else {
      dispatch(updateAppStatus(0));
    }
  }, [read, verifyStatus]);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });


  return (
    <ChakraProvider theme={theme}>
      {!appStatus ? (
        <>{!read && !_.isEmpty(userData) ? <NoPermissions /> : <Loader />}</>
      ) : (
        <Router>
          <Layout>
            <ToastContainer position="bottom-center" />
            <Routes>
              {/* Monthly*/}

              <Route path="/" element={<Dashboard />} />
              {/* organization */}
              <Route path="*" element={<Navigate to="/" replace />} />

              {/* Smses daily*/}
              <Route
                path="/daily/messages-analysis/"
                element={<DailyMessagesAnalysis />}
              />

              <Route
                path="/daily/message-details/"
                element={<DailyMessageDetails />}
              />

              {/* Smses Monthly*/}
              <Route
                path="/monthly/messages-analysis/"
                element={<MonthlyMessagesAnalysis />}
              />

              <Route
                path="/monthly/message-details/"
                element={<DailyMessageDetails />}
              />
            </Routes>
          </Layout>
        </Router>
      )}
    </ChakraProvider>
  );
}
export default App;
