import { useEffect, useState } from "react";
import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import {
  setDateRange,
  updateReportRange,
} from "../../store/actions/defaultAction";

import { REPORT_RANGES } from "../../store/constants";
import { navLinks } from "../../store/constants";
import dayjs from "dayjs";

const ReportRangeSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reportRange } = useSelector((store) => store.defaultReducer);
  const [showSelect, setShowSelect] = useState(!reportRange.length ? 1 : 0);

  const reportRangeStr = {
    month: "Monthly",
    day: "Daily",
  }[reportRange];

  useEffect(() => {
    let report = null;
    let date = [];
    switch (reportRange) {
      case REPORT_RANGES.MONTH:
        report = navLinks.find((a) => a.name === REPORT_RANGES.MONTH)?.links[0]
          ?.links[0]?.link;
        date = [
          dayjs().subtract(1, "month").startOf("month").unix(),
          dayjs().subtract(1, "month").endOf("month").unix(),
        ];

        break;
      case REPORT_RANGES.DAY:
        report = navLinks.find((a) => a.name === REPORT_RANGES.DAY)?.links[0]
          ?.links[0]?.link;
        date = [
          dayjs().subtract(1, "day").startOf("day").unix(),
          dayjs().subtract(1, "day").endOf("day").unix(),
        ];
        break;
      default:
        break;
    }

    dispatch(setDateRange(date));
    report = report || "/";
    navigate(report);
  }, [reportRange]);

  return (
    <Box>
      <Box
        h="40px"
        lineHeight="40px"
        bg="var(--blue)"
        borderRadius="20px"
        padding="0 20px"
      >
        <HStack
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setShowSelect(!showSelect)}
        >
          <Text
            as="label"
            color="var(--white)"
            fontSize="14px"
            fontWeight={600}
          >
            {reportRange.length
              ? reportRangeStr + " Reports"
              : "Select Report Range"}
          </Text>
          <Icon
            as={showSelect ? IoChevronUpOutline : IoChevronDownOutline}
            boxSize="16px"
            color="var(--white)"
          />
        </HStack>
      </Box>
      <Box
        bg="#f7f7f7"
        borderRadius="5px"
        fontSize="14px"
        overflow="hidden"
        mt="10px"
        display={showSelect ? "block" : "none"}
      >
        <Box
          p="10px 10px"
          _hover={{ background: "#ececec" }}
          cursor="pointer"
          onClick={() => {
            if (reportRange !== REPORT_RANGES.MONTH)
              dispatch(updateReportRange(REPORT_RANGES.MONTH));
            setShowSelect(0);
          }}
        >
          Monthly
        </Box>
        <Box
          p="10px 10px"
          _hover={{ background: "#ececec" }}
          cursor="pointer"
          onClick={() => {
            dispatch(updateReportRange(REPORT_RANGES.DAY));
            setShowSelect(0);
          }}
        >
          Daily
        </Box>
      </Box>
    </Box>
  );
};

export default ReportRangeSelection;
