import React from "react";
import { CardBody, Card, Box } from "@chakra-ui/react";
import JSONPretty from "react-json-pretty";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import _ from "lodash";

const MessageCard = ({ id }) => {
  const { messages } = useSelector((state) => state.defaultReducer);

  const messagesClone = _.cloneDeep(messages);
  const messageData = messagesClone.find((a) => a.id === id);
  const { created } = messageData;

  return (
    <Box>
      <strong>Created</strong>:{" "}
      {dayjs.unix(created).format("YYYY-MM-DD HH:mm:ss")}
      <br />
      <strong>Delivery Info Created</strong>:{" "}
      {messageData?.deliveryInfo?.created &&
        dayjs
          .unix(messageData.deliveryInfo.created)
          .format("YYYY-MM-DD HH:mm:ss")}
      <JSONPretty data={messageData} />
    </Box>
  );
};

export default MessageCard;
