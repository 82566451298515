import { Box, SimpleGrid } from "@chakra-ui/react";
import { TbMessage2X } from "react-icons/tb";
import { LuCalendarDays } from "react-icons/lu";
import { BiSolidMessageSquareCheck } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import StatsCard from "../../../StatsCard";
import { generateDateArray } from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";

const MonthlyMessagesAnalysis = () => {
  const { messages, dateRange } = useSelector((state) => state.defaultReducer);

  const startTimeStamp = dateRange[0];
  const endTimeStamp = dateRange[1];
  const daysArray = generateDateArray(startTimeStamp, endTimeStamp);
  const chart = daysArray.map((fullDate) => {
    const dayMessages = messages.filter(
      (a) => dayjs.unix(a.created).format("MM-DD-YYYY") === fullDate
    );

    return {
      day: dayjs(fullDate).date(),
      total: dayMessages.length,
    };
  });

  const totalMessages = messages.length;
  const deliveredMessages = messages.filter(
    (a) => Number(a.deliveryStatus) === 1
  ).length;
  const undeliveredMessages = messages.filter(
    (a) => Number(a.deliveryStatus) === 0
  ).length;

  const max = Math.max(...chart.map((a)=>a.total));
  let peakDay = chart.find((a)=>a.total === max)?.day || "NA";

    const stats = [
    {
      title: "Total Messages",
      stat: totalMessages,
      icon: TiMessages,
      iconColor: "var(--orange)",
    },
    {
      title: "Delivered Messages",
      stat: deliveredMessages,
      icon: BiSolidMessageSquareCheck,
      iconColor: "var(--green)",
    },
    {
      title: "Peak Day",
      stat: peakDay,
      icon: LuCalendarDays,
      iconColor: "var(--green)",
    },
    {
      title: "Undelivered Messages",
      stat: undeliveredMessages,
      icon: TbMessage2X,
      iconColor: "var(--red)",
    },
  ];

  return (
    <Box mt={4}>
      <Box mt={4}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={0} mb={"10px"}>
          {stats.map((a, i) => (
            <Box
              key={i}
              px="20px"
              py="15px"
              mb="5px"
              border="1px solid var(--borderGrey)"
              borderRight={{
                base: "1px solid #E5E4E5",
                md: [3, 7].includes(i) ? "1px solid #E5E4E5" : "none",
              }}
              borderRadius={{
                base: "10px",
                md: [0, 4].includes(i)
                  ? "10px 0 0 10px"
                  : [3, 7].includes(i)
                  ? "0 10px 10px 0"
                  : "0",
              }}
            >
              <StatsCard
                title={a.title}
                stat={a.stat}
                statNumberId={a.statNumberId}
                icon={a.icon}
                iconColor={a.iconColor}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <Box>
        <LineGraph
          chart={chart}
          title={`Total messages sent, Day by Day comparison`}
          lineName="Total"
          lineDataKey="total"
          XAxisDataKey="day"
          XAxisName="Day"
        />
      </Box>
    </Box>
  );
};

export default MonthlyMessagesAnalysis;
