import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Select from "../averly/Select";
import { MESSAGE_STATUS } from "../../store/constants";
import { updateMessageStatus } from "../../store/actions/defaultAction";

const MessageStatusSelection = () => {
  const dispatch = useDispatch();
  const { messageStatus } = useSelector(
    (store) => store.defaultReducer
  );
  const options = [
    { name: "Delivered", id: MESSAGE_STATUS.DELIVERED },
    {
      name: "Undelivered",
      id: MESSAGE_STATUS.UNDELIVERED,
    },
  ];

  const handleOnchange = (messageStatus) => {
    dispatch(updateMessageStatus(messageStatus));
  };

  return (
    <Select
      label="Message Status"
      placeholder="All Messages"
      value={messageStatus}
      options={options}
      onChange={handleOnchange}
    />
  );
};

export default MessageStatusSelection;
