import { Box, Center, HStack, Icon, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { IoCalendarClearOutline, IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useState, useEffect, useRef } from "react";
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Africa/Windhoek");

const MonthlyPicker = ({styling, value, maxDate, onChange}) => {
    const currentYear = dayjs().year();
    const currentMonth = dayjs().format('MMMM');
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState(dayjs().year());
    const [selectedDate, setSelectedDate] = useState(
        value.length
            ? `${dayjs.unix(value).startOf('month').format("DD MMM YYYY")} - ${dayjs.unix(value).endOf('month').format("DD MMM YYYY")}`
            : `${dayjs().startOf('month').format("DD MMM YYYY")} - ${dayjs().endOf('month').format("DD MMM YYYY")}`
    );
    const [showCalendar, setShowCalendar] = useState(0);
    const calendarRef = useRef(null);

    const months = [];
    for (let i = 0; i < 12; i++) {
        const month = dayjs().month(i);
        months.push({
            name: month.format("MMMM"),
            monthIndex: month.month(),
            index: i
        });
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside) };
    }, []);

    useEffect(() => {
        updateValue(value)
    }, [value]);

    const updateValue = (value) => {
        value = value == null || !(value != null && value.length && value[0].toString().length )
            ?  dayjs().startOf("week").unix()
            : value[0];
        const startDate = dayjs.unix(value);

        const month = startDate.month();
        const year = startDate.year();
        setSelectedMonth(month);
        setSelectedYear(year);
        
        const startOfMonthUnix = dayjs().year(year).month(month).startOf('month').unix();
        const endOfMonthUnix = dayjs().year(year).month(month).endOf('month').unix();
        setStringDate([startOfMonthUnix, endOfMonthUnix]);
    }

    const handleYearChange = (a) => {
        const newYear = selectedYear + a;
        if (newYear <= currentYear) { setSelectedYear(newYear) }
    }

    const handleDateChange = (month) => {
        const startOfMonthUnix = dayjs().year(selectedYear).month(month).startOf('month').unix();
        const endOfMonthUnix = dayjs().year(selectedYear).month(month).endOf('month').unix();
        setStringDate([startOfMonthUnix, endOfMonthUnix]);
        onChange([startOfMonthUnix, endOfMonthUnix]);
    }

    const setStringDate = (date) => {
        // get start and end of month and create human readable date
        const startDate = dayjs.unix(date[0]).format("DD MMM YYYY");
        const endDate = dayjs.unix(date[1]).format("DD MMM YYYY");
        setSelectedDate(`${startDate} - ${endDate}`);
    }

    // calendar styling
    const calendarStyling = {
        [styling.calendar.placement[0]]: styling.calendar.spacing[0],
        [styling.calendar.placement[1]]: styling.calendar.spacing[1]
    };

    return (
        <Box
            className="averly-date-picker month-picker"
            w={styling.width}
            h={styling.height}
            cursor="pointer"
            zIndex="999999"
            position="relative"
        >
            <VStack spacing={styling.spacing} position="relative">
                <HStack spacing="5px" onClick={() => !showCalendar && setShowCalendar(1)}>
                    <Icon as={IoCalendarClearOutline} boxSize="16px" color="#171C1E" />
                    <Text fontSize={styling.fontSize} h={styling.height} display="inline-block" overflow="hidden">{ selectedDate }</Text>
                </HStack>
                <Box
                    ref={calendarRef}
                    bg="#F6F6F9"
                    w="294px"
                    h="auto"
                    position="absolute"
                    p="9px 5px 5px"
                    borderRadius="5px"
                    display={showCalendar ? "block" : "none"}
                    {...calendarStyling}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <VStack spacing="0">
                        <HStack className="header" w="100%" justifyContent="space-between">
                            <Center className="iconBackground" w="30px" h="30px" onClick={() => handleYearChange(-1)}>
                                <Icon as={IoArrowBackOutline} className="icon" boxSize="16px" />
                            </Center>
                            <Text fontSize="14px" color="#000" textAlign="center">{selectedYear}</Text>
                            <Center className="iconBackground" w="30px" h="30px" onClick={() => handleYearChange(1)}>
                                <Icon as={IoArrowForwardOutline} className="icon" boxSize="16px" />
                            </Center>
                        </HStack>
                        <Wrap spacing="5px" bg="#fff" w="100%" p="5px" borderRadius="3px" mt="10px">
                            {
                                months.map((a, i) => {
                                    
                                    // check if month is greater than maxDate
                                    const maxDateStatus = selectedYear === currentYear && a.monthIndex > dayjs.unix(maxDate).month() ? 0 : 1;

                                    return <WrapItem key={i} w="88px">
                                        <Center 
                                            key={i} 
                                            w="100%" 
                                            h="44px" 
                                            cursor={maxDateStatus ? "pointer" : "not-allowed"}
                                            color={
                                                !maxDateStatus
                                                    ?   "#afafaf"
                                                    :   selectedMonth.toString().length && selectedMonth === a.index ? "#fff" : "#697387"
                                            }
                                            bg={ 
                                                !maxDateStatus
                                                    ?   "#d8d9da"
                                                    :   selectedMonth.toString().length && selectedMonth === a.index
                                                        ?   "#344054"
                                                        :   (
                                                                a.name.toLowerCase() === currentMonth.toLowerCase() 
                                                                && selectedYear === currentYear
                                                            ) ? "#C3F0C2"
                                                    : "#EAECF0" 
                                            } 
                                            borderRadius="3px"
                                             _hover={
                                                !maxDateStatus
                                                    ?   { bg: "#d8d9da", color: "#afafaf" }
                                                    :   { bg: "#344054", color: "#fff" }
                                             }
                                            onClick={() => {
                                                if (!maxDateStatus) return;
                                                handleDateChange(a.index);
                                                setTimeout(() => {
                                                    setShowCalendar(0)
                                                }, 200);
                                            }}
                                        >
                                            <Text fontSize="14px" color="inherit">{a.name}</Text>
                                        </Center>
                                    </WrapItem>
                                })
                            }
                        </Wrap>
                    
                    </VStack>
                </Box>
            </VStack>
        </Box>
    );
}

export default MonthlyPicker;