import {
  Stat,
  StatLabel,
  StatNumber,
  Icon,
} from '@chakra-ui/react';
import { useId } from 'react';
import { NavLink } from 'react-router-dom';


function StatsCard({
  title, stat, statNumberId, icon, iconColor, to
}) {
  const generatedId = useId();
  const id = statNumberId || generatedId;

  return (
    <Stat
      as={to && to.length ? NavLink : 'div'}
    >
      <StatLabel fontWeight="400" color="var(--gray-8)">
        {title}
      </StatLabel>
      <StatNumber fontSize="24px" fontWeight="600" color="var(--black-dry)" id={id}>
        {stat}
      </StatNumber>
      { icon ? <Icon as={icon} color={iconColor} boxSize="28px" position="absolute" bottom="-5px" right="-10px" /> : null}
    </Stat>
  );
}

export default StatsCard;
