import { Box, SimpleGrid } from "@chakra-ui/react";
import {
  IoTimeOutline,
} from "react-icons/io5";
import { TbMessage2X } from "react-icons/tb";
import { BiSolidMessageSquareCheck } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import StatsCard from "../../../StatsCard";
import { generateHoursArray } from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";

const DailyMessagesAnalysis = () => {
  const { messages, dateRange } = useSelector((state) => state.defaultReducer);
  const startTimeStamp = dateRange[0];
  const endTimeStamp = dateRange[1];
  const hoursArray = generateHoursArray(startTimeStamp, endTimeStamp);
  const chart = hoursArray.map((hour) => {
    const hourMessages = messages.filter(
      (a) => dayjs.unix(a.created).format("h A") === hour
    );

    return {
      hour,
      total: hourMessages.length,
    };
  });

  const totalMessages = messages.length;
  const deliveredMessages = messages.filter(
    (a) => Number(a.deliveryStatus) === 1
  ).length;
  const undeliveredMessages = messages.filter(
    (a) => Number(a.deliveryStatus) === 0
  ).length;
  const max = Math.max(...chart.map((a) => a.total));
  const peakHour = chart.find((a) => a.total === max)?.hour || "NA";

  const stats = [
    {
      title: "Total Messages",
      stat: totalMessages,
      icon: TiMessages,
      iconColor: "var(--orange)",
    },
    {
      title: "Delivered Messages",
      stat: deliveredMessages,
      icon: BiSolidMessageSquareCheck,
      iconColor: "var(--green)",
    },
    {
      title: "Peak Hour",
      stat: peakHour,
      icon: IoTimeOutline,
      iconColor: "var(--green)",
    },
    {
      title: "Undelivered Messages",
      stat: undeliveredMessages,
      icon: TbMessage2X,
      iconColor: "var(--red)",
    },
  ];

  return (
    <Box mt={4}>
      <Box mt={4}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={0} mb={"10px"}>
          {stats.map((a, i) => (
            <Box
              key={i}
              px="20px"
              py="15px"
              mb="5px"
              border="1px solid var(--borderGrey)"
              borderRight={{
                base: "1px solid #E5E4E5",
                md: [3, 7].includes(i) ? "1px solid #E5E4E5" : "none",
              }}
              borderRadius={{
                base: "10px",
                md: [0, 4].includes(i)
                  ? "10px 0 0 10px"
                  : [3, 7].includes(i)
                  ? "0 10px 10px 0"
                  : "0",
              }}
            >
              <StatsCard
                title={a.title}
                stat={a.stat}
                statNumberId={a.statNumberId}
                icon={a.icon}
                iconColor={a.iconColor}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <Box>
        <LineGraph
          chart={chart}
          title={`Total messages sent, Hour by Hour comparison`}
          lineName="Total"
          lineDataKey="total"
        />
      </Box>
    </Box>
  );
};

export default DailyMessagesAnalysis;
