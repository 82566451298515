import _ from "lodash";
import { useSelector } from "react-redux";
import useRoles from "./useRoles";
import { PRODUCT_SERVICE_ID } from "../store/constants";

const usePermissions = () => {
  let { userPermissions } = useSelector((store) => store.authReducer);
  const { roleId, isSuperAdmin } = useRoles();
  userPermissions = userPermissions.find(
    (a) => a.roleId === roleId && a.productServiceId === PRODUCT_SERVICE_ID
  );

  return {
    create: isSuperAdmin ? 1 : (Number(userPermissions?.create) || 0),
    read: isSuperAdmin ? 1 : (Number(userPermissions?.read) || 0),
    update: isSuperAdmin ? 1 : (Number(userPermissions?.update) || 0),
    delete: isSuperAdmin ? 1 : (Number(userPermissions?.delete) || 0),
  };
};

export default usePermissions;
