import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/react";
import dayjs from "dayjs";

import { setDateRange } from "../../store/actions/defaultAction";
import AverlyDatePicker from "../averly/averly-date-picker";

const DatePicker = () => {
  const dispatch = useDispatch();
  const { dateRange, reportRange } = useSelector((store) => store.defaultReducer);

  const handleDateChange = (date) => dispatch(setDateRange(date));
  const calendarType = reportRange === 'realtime' ? 'day' : reportRange;

  // set maxDate to limit selecting future dates
  let maxDate = dayjs().endOf("month").unix();
  if(calendarType === "week") dayjs().endOf("week").unix();
  if(calendarType === "day") dayjs().endOf("day").unix();

  return (
    <Box 
      w="100%"
      h="56px"
      borderRadius="8px"
      border="1px solid #b0b0b0"
      p="12px 12px 9px 12px"
    >
      <AverlyDatePicker
        styling={{ 
          width: "100%", 
          height: "20px", 
          fontSize: "16px", 
          spacing: "10px", 
          calendar: {
            placement: ["top", "left"], //vertical(top, bottom), horizontal(left, right)
            spacing: ["30px", "-13px"], //vertical, horizontal
          }
        }}
        label={calendarType}
        calendarType={calendarType} // month, week, day
        maxDate={maxDate}
        value={dateRange.length && dateRange[0].toString().length ? dateRange : [dayjs().startOf("month").unix(), ""]}
        onChange={handleDateChange}
      />
    </Box>
  );
};

export default DatePicker;
